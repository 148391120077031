import React from 'react'
import Layout from "../components/App/YftelLayout"
import Navbar from "../components/App/YftelNavbar2"
import PageBanner from '../components/Common/YftelPageBanner'
import Footer from "../components/App/YftelFooter"
import CaseStudiesDetailsContent from '../components/CaseStudiesDetailsAAA/CaseStudiesDetailsContent'
import RelatedProjects from '../components/CaseStudiesDetailsAAA/RelatedProjects'

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="De 0 a 100,000 suscriptores" 
                homePageText="Inicio" 
                homePageUrl="/" 
                activePageText="Detalles de Caso de Estudio" 
            />
            <CaseStudiesDetailsContent />
            <Footer />
        </Layout>
    );
}

export default CaseDetails
